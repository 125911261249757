import React, { useState } from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'

import categories from '../../content/categories'
import { useInterval } from '../hooks'
import { capitalise, randomColors } from '../util'
import Image from 'gatsby-image'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { Heading, Paragraph } from '../components/typography'
import SlideyDoo from '../components/slidey-doo'

const Homepage = ({ location, data, className }) => {
  const { coverImages } = data
  const [colors, setColors] = useState(randomColors(categories.length))
  const [partyModeEnabled, setPartyModeEnabled] = useState(location.hash === '#party-mode')

  useInterval(() => {
    if (partyModeEnabled) {
      refresh()
    }
  }, 500)

  const refresh = () => setColors(randomColors(categories.length))

  return (
    <Layout header={false} className={className}>
      <SEO
        title="Home"
        keywords={[`blog`, `gatsby`, `javascript`, `react`]}
      />
      <section className="lg:flex items-center px-8 mb-4 lg:-mb-8">
        <Image
          fixed={data.avatar.childImageSharp.fixed}
          alt={""}
          className="lg:w-auto border-8 border-white rounded shadow"
          style={{
            minWidth: 256,
          }}
        />
        <div className="max-w-3xl flex mt-4 lg:-mt-4 pb-6 lg:pb-0 lg:ml-4">
          <div className="text-6xl leading-none -ml-2 sm:ml-0">
            <span className="lg:hidden">↑</span>
            <span className="hidden lg:block">&larr;</span>
          </div>
          <div className="ml-1 sm:ml-4">
            <Heading level="1" className="lg:leading-none lg:mb-2">
              Dave Calnan
            </Heading>
            <div className="pl-1">
              <Paragraph>
                made this website. Thanks for being here, it means a lot.
              </Paragraph>
              <Paragraph>
                Here I like to share my thoughts, code, and experiences.
              </Paragraph>
              <Paragraph>
                Don't like the colours?{" "}
                <button
                  className="text-blue-500 focus:outline-none"
                  onClick={refresh}
                >
                  Refresh
                </button>{" "}
                and try again.{" "}
                {partyModeEnabled ? (
                  <span>
                    Too much?{" "}
                    <button
                      className="text-orange-800 focus:outline-none"
                      onClick={() => setPartyModeEnabled(false)}
                    >
                      Disable party mode
                    </button>
                    .
                  </span>
                ) : (
                  <span>
                    Or, if you're feeling brave,{" "}
                    <button
                      className="text-pink-500 focus:outline-none"
                      onClick={() => setPartyModeEnabled(true)}
                    >
                      enable party mode
                    </button>
                    .
                  </span>
                )}
              </Paragraph>
            </div>
          </div>
        </div>
      </section>
      {categories.map(({ name, description }, index) => (
        data[name] ? (
          <section
            key={name}
            id={name}
            className={`bg-${colors[index]}-200 pt-12 pb-8`}
          >
            <SlideyDoo
              heading={capitalise(name)}
              details={description}
              link={`/${name}`}
              posts={data[name].edges}
              coverImages={coverImages.edges.filter(({ node }) => node.absolutePath.includes(name))}
              accent={`${colors[index]}-600`}
            />
          </section>
        ) : null
      ))}
    </Layout>
  )
}

export default styled(Homepage)``

export const pageQuery = graphql`
  query {
    avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
      childImageSharp {
        fixed(width: 256, height: 256) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    ramblings: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC },
      filter: { fileAbsolutePath: { regex: "/content/ramblings/" } }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
    packages: allMarkdownRemark(
      sort: { fields: [frontmatter___priority], order: ASC },
      filter: { fileAbsolutePath: { regex: "/content/packages/" } }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
    ventures: allMarkdownRemark(
      sort: { fields: [frontmatter___priority], order: ASC },
      filter: { fileAbsolutePath: { regex: "/content/ventures/" } }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            dates
            title
            description
          }
        }
      }
    }
    code: allMarkdownRemark(
      sort: { fields: [frontmatter___priority], order: ASC },
      filter: { fileAbsolutePath: { regex: "/content/code/" } }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
    coverImages: allFile(
      filter: { absolutePath: { regex: "//content/.*/cover-image/" } }
    ) {
      edges {
        node {
          absolutePath
          relativeDirectory
          childImageSharp {
            fixed(width: 256) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`
