import React from 'react'
import styled from 'styled-components'
import tw from 'tailwind.macro'

import { Link } from 'gatsby'
import Image from 'gatsby-image'
import { Heading, Small } from './typography'

const BlogPostPreview = ({ post, accent = 'white', className }) => {
  const { slug, title, description, date, coverImage } = post

  return (
    <div className={`w-64 overflow-y-hidden rounded shadow-md bg-white mr-4 ${className}`}>
      <Link to={slug}>
        <Image
          fixed={coverImage.node.childImageSharp.fixed}
          className={`h-36 border-b-4 border-${accent}`}
          imgStyle={tw`object-top`}
        />
      </Link>
      <div className="h-48 flex flex-col p-4">
        <Heading level="5" responsive={false}>
          <Link to={slug}>{title}</Link>
        </Heading>
        <Small className="text-xs mb-0">{date}</Small>
        <Small className="flex-grow mb-0" dangerouslySetInnerHTML={{ __html: description }} />
        <Link className="font-display underline" to={slug}>
          Read more &rarr;
        </Link>
      </div>
    </div>
  )
}

export default styled(BlogPostPreview)``
