import React from 'react'
import styled from 'styled-components'
import tw from 'tailwind.macro'

import { Link } from 'gatsby'
import { Heading } from './typography'
import PostPreview from './post-preview'

const SlideyDoo = ({ heading, details, link, posts, coverImages, accent, className }) => (
  <div className={className}>
    <div className="px-8 mb-4">
      <Link to={link}>
        <Heading level="2">{heading}</Heading>
      </Link>
      <p>{details}</p>
    </div>
    <div className="mx-auto flex overflow-x-scroll scrolling-touch sm:scrolling-auto">
      {posts.map(({ node }) => (
        <div
          key={node.fields.slug}
          className="flex-shrink-0"
        >
          <PostPreview
            post={{
              title: node.frontmatter.title,
              slug: node.fields.slug,
              date: node.frontmatter.date || node.frontmatter.dates,
              description: node.frontmatter.description || node.excerpt,
              coverImage: coverImages.find(image => node.fields.slug === `/${ image.node.relativeDirectory }`)
            }}
            accent={accent}
          />
        </div>
      ))}
    </div>
  </div>
)

export default styled(SlideyDoo)`
  .overflow-x-scroll {
    &::-webkit-scrollbar,
    &::-webkit-scrollbar-track,
    &::-webkit-scrollbar-thumb {
      ${tw`hidden bg-transparent`}
    }

    & > div {
      &:first-child {
        ${tw`ml-8`}
      }

      ${tw`pr-4`}
    }
  }

  ${PostPreview} {
    ${tw`my-2`}
  }
`